import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../components/breadcrumb/Breadcrumb";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import HTTPService from "../../utils/axios";
import "./ProgramtabStyle.css";
import moment from "moment";
import buglogo from "../assets/BugBounty.png";
import bigLogo from "../assets/Bugbusterlabs 1.png";
import useFetchCustomer from "../HandleApis/Customer/FetchCustomer";
import AgreementModal from "../Researcher-part/ResearcherProgramTab/AgreementModal/AgreementModal";
import ViewInfo from "../common/ViewInfo";
import { Link } from "react-router-dom";
import AppContext from "../Context/AppContext";
import PtabDetailSkeleton from "../components/SkeletonLoader/PtabDetailSkeleton";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../reducers/authSlice";

export default function AdminProgramDetails() {
  const { userInfo } = useSelector(getUserInfo);
  const { invokeApi } = HTTPService();
  const { id } = useParams();
  const { customerOpt } = useFetchCustomer();
  const [customer, setCustomer] = useState({});
  const [program, setProgram] = useState();
  const [scopeUrl, setScopeUrl] = useState([]);
  const [outUrl, setOutUrl] = useState([]);
  const [changeStatusVal, setChangeStatusVal] = useState();
  const [agreementModal, setAgreementModal] = useState(false);
  const { loading, setLoading } = useContext(AppContext);
  const [customerLoad, setCustomerLoad] = useState(false);
  const [safeHarbor, setSafeHarbor] = useState(false);

  const fetchProgram = () => {
    setLoading(true);
    invokeApi({
      method: "GET",
      url: `programs/${id}`,
    })
      .then((response) => {
        setLoading(false);
        setProgram(response);
        setChangeStatusVal(response?.program_status);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const fetchCustomer = () => {
    setCustomerLoad(true);
    let customerDetails = customerOpt?.results?.find(
      (item) => program?.customer === item.id
    );
    setCustomerLoad(false);
    setCustomer(() => {
      return { ...customerDetails };
    });
    console.log("customer details", customer);
  };

  const fetchScopeUrls = () => {
    const urls = [];

    if (program && program.scope_items_url1) {
      urls.push(program.scope_items_url1);
    }
    if (program && program.scope_items_url2) {
      urls.push(program.scope_items_url2);
    }
    if (program && program.scope_items_url3) {
      urls.push(program.scope_items_url3);
    }
    if (program && program.scope_items_url4) {
      urls.push(program.scope_items_url4);
    }
    if (program && program.scope_items_url5) {
      urls.push(program.scope_items_url5);
    }

    setScopeUrl({ innerItem: [...urls], structure: "obj1" });
    console.log("url", scopeUrl);
    console.log("typeof", typeof scopeUrl);
  };
  const fetchOutUrls = () => {
    const urls = [];
    if (program && program.out_Of_scope_item_url1) {
      urls.push(program.out_Of_scope_item_url1);
    }
    if (program && program.out_Of_scope_item_url2) {
      urls.push(program.out_Of_scope_item_url2);
    }
    if (program && program.out_Of_scope_item_url3) {
      urls.push(program.out_Of_scope_item_url3);
    }
    if (program && program.out_Of_scope_item_url4) {
      urls.push(program.out_Of_scope_item_url4);
    }
    if (program && program.out_Of_scope_item_url5) {
      urls.push(program.out_Of_scope_item_url5);
    }
    setOutUrl({ innerItem: [...urls], structure: "obj1" });
  };

  useEffect(() => {
    if (id) {
      fetchProgram();
      // fetchProgramStatus();
      // fetchSubmissions();
      // fetchStats();
    }
  }, [id]);

  useEffect(() => {
    fetchCustomer();
  }, [program, customerOpt]);

  useEffect(() => {
    if (program) {
      fetchScopeUrls();
      fetchOutUrls();
    }
  }, [program]);

  const scopeDetailsOption = [
    "Program Tags",
    "Scope Title",
    "Scope Item URL",
    "Out of Scope Item URL",
    "Expected Vulnerabilities",
    "Types of Testing Allowed",
    "Languages & Frameworks",
    "Asset Environments",
  ];

  const scopeDetailsField = [
    program?.project_tags,
    program?.scope_title,
    scopeUrl,
    outUrl,
    program?.expected_vulnerability_types,
    program?.testing_allowed,
    program?.language_framworks,
    program?.asset_environments,
  ];

  const checkDocument = async () => {
    invokeApi({
      method: "GET",
      url: `/safe-harbor/${id}`,
    })
      .then((data) => {
        if (data.status == "accepted") {
          setSafeHarbor(true);
        }
        console.log("researcher details", data);
      })
      .catch((error) => {
        return null;
      });
  };
  useEffect(() => {
    checkDocument();
  }, []);

  const Breadcrumbname = {
    home: "/admin/home",
    Managment: "/admin/program/dashboard",
    Programs: "/admin/program/dashboard",
    //[dynamicBreadCrumb()]: "",
  };
  return (
    <>
      {loading ? (
        <PtabDetailSkeleton />
      ) : (
        <div className="container-fluid py-4 pb-0">
          <div className="row p-0">
            <div className="mainDivTopPart">
              <div className="col-12 ">
                <div className="d-flex">
                  <div
                    className="col-4 demoTitleDiv"
                    style={{ padding: "20px 17px" }}
                  >
                    <div
                      className=" demoTitleDiv2 d-flex justify-content-center align-items-center"
                      style={{ backgroundColor: "#fff" }}
                    >
                      {/* <div className="row d-flex justify-content-end">
              <div className="demoTextP">
                <p>Demo</p>
              </div>
            </div> */}
                      {/* <div className="row d-flex justify-content-center">
              <div>
                <p
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  Program Image
                </p>
              </div>
            </div> */}
                      <div className="row">
                        <div className="d-flex justify-content-center">
                          {program?.logo ? (
                            <img
                              style={{ height: "90px", objectFit: "contain" }}
                              src={program?.logo}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ height: "90px", objectFit: "contain" }}
                              src={bigLogo}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 className="demoTitleDivTitle mb-0">
                        Customer Details:
                      </h5>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex pt-3">
                        <div className="col-4">
                          <p
                            className="mb-0 "
                            style={{ color: "#525252", fontWeight: "600" }}
                          >
                            ID
                          </p>
                        </div>
                        <div className="col-8">
                          <p
                            className="mb-0 "
                            style={{ color: "#525252", fontWeight: "600" }}
                          >
                            {customerLoad ? (
                              <Skeleton height={30} width={120} />
                            ) : (
                              customer.user_id
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-12 d-flex pt-1">
                        <div className="col-4">
                          <p
                            className="mb-0 "
                            style={{ color: "#525252", fontWeight: "600" }}
                          >
                            Entity Name
                          </p>
                        </div>
                        <div className="col-8">
                          <p
                            className="mb-0 "
                            style={{ color: "#525252", fontWeight: "600" }}
                          >
                            {customerLoad ? (
                              <Skeleton height={30} width={120} />
                            ) : (
                              customer.entity_name
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="col-12 d-flex pt-1">
                        <div className="col-4">
                          <p
                            className="mb-0 "
                            style={{ color: "#525252", fontWeight: "600" }}
                          >
                            Full Name
                          </p>
                        </div>
                        <div className="col-8">
                          {customerLoad ? (
                            <div>
                              <Skeleton height={30} width={120} />
                            </div>
                          ) : (
                            <p
                              className="mb-0 "
                              style={{ color: "#525252", fontWeight: "600" }}
                            >
                              {customer.first_name} {customer.last_name}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-12 d-flex pt-1">
                        <div className="col-4">
                          <p
                            className="mb-0 "
                            style={{ color: "#525252", fontWeight: "600" }}
                          >
                            Username
                          </p>
                        </div>
                        <div className="col-8">
                          <p
                            className="mb-0 "
                            style={{ color: "#0d47a1", fontWeight: "600" }}
                          >
                            {customer.username}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-8 demoTitleDiv">
                    <div className="row d-flex justify-content-between">
                      <div className="col-10 demoTitleDivTitle">
                        <h5 className="demoTitleDivTitle mb-0">
                          {program?.program_title}
                          <span className="">({program?.program_id})</span>
                        </h5>
                      </div>
                      <div className="col-2 d-flex">
                        <div
                          style={{
                            backgroundColor: "#FBD38D",
                            border: "1px solid #ddd",
                            borderRadius: "20px",
                            overflow: "hidden",
                          }}
                          className=" d-flex justify-content-center"
                        >
                          <span
                            className={
                              program?.program_status === "pending"
                                ? "customerStatus customerStsPending"
                                : program?.program_status === "hold"
                                  ? "customerStatus customerStsHold"
                                  : program?.program_status === "closed"
                                    ? "customerStatus customerStsClosed"
                                    : program?.program_status === "approved"
                                      ? "customerStatus customerStsApproved"
                                      : program?.program_status === "in_progess"
                                        ? "customerStatus customerInProgress"
                                        : ""
                            }
                            style={{ textTransform: "capitalize" }}
                          >
                            {program?.program_status === "in_progess"
                              ? "In-Progress"
                              : program?.program_status}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="iBar">
                        <div className="iBarIconDiv">
                          <i className="fa-solid fa-circle-info"></i>
                        </div>
                        <div className="d-flex align-items-center justify-content-center ms-3">
                          <p
                            className="mb-0 "
                            style={{ color: "#040404", fontWeight: "600" }}
                          >
                            Reminder:
                          </p>
                          <span
                            className="ms-2"
                            style={{ color: "#040404", fontWeight: "500" }}
                          >
                            Verify Safe Harbor Compliance before Signing
                            Submission Agreement.
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4 d-flex">
                      <div className="col-7 flex-column flagRow ">
                        <div className="d-flex flagRowChild">
                          <div className="flagRowChildI">
                            <div>
                              <i className="fa-solid fa-flag"></i>
                            </div>
                          </div>
                          <div className="d-flex flagRowChildText">
                            <p>
                              ${program?.p5_reward_amt}-$
                              {program?.p1_reward_amt}
                            </p>
                            <span>Per Vulnerability</span>
                          </div>
                        </div>
                        <div className="d-flex flagRowChild">
                          <div className=" flagRowChildIStart">
                            <div>
                              <i className="fa-solid fa-star"></i>
                            </div>
                          </div>
                          <div className="d-flex flagRowChildText">
                            <p>Up to ${program?.maximun_budget}</p>
                            <span>Maximum Reward</span>
                          </div>
                        </div>
                        <div className="d-flex flagRowChild">
                          <div className=" flagRowChildIStart">
                            <div>
                              <i className="fa-solid fa-bolt"></i>
                            </div>
                          </div>
                          <div className="d-flex align-items-center flagRowChildText">
                            <p>Program Type :</p>
                            <div>
                              <p>{program?.program_type}</p>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flagRowChild">
                          <div className="flagRowChildIHand">
                            <div>
                              <i className="fa-solid fa-handshake"></i>
                            </div>
                          </div>
                          <div className="d-flex align-items-center flagRowChildText">
                            <p>Program Package :</p>
                            <div>
                              <p>{program?.program_package}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-5 flagRow">
                        <div className="d-flex flex-column ">
                          <div className="d-flex flagRowChild">
                            <div className=" flagRowChildIStart">
                              <div>
                                <i className="fa-solid fa-bolt"></i>
                              </div>
                            </div>
                            <div className="d-flex align-items-center flagRowChildText">
                              <p>Severity :</p>
                              <span>{program?.severity}</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center mt-1">
                            <div className="d-flex">
                              <div className="flagRowChildIHand">
                                <div>
                                  <i className="fa-solid fa-handshake"></i>
                                </div>
                              </div>
                              <div className="d-flex flagRowChildText">
                                <p style={{ marginTop: "0px" }}>Safe harbor</p>
                              </div>
                            </div>
                            <div className="">
                              <button
                                className="viewAgreementBtn"
                                onClick={() => setAgreementModal(true)}
                                disabled={safeHarbor}
                              >
                                {safeHarbor ? "Submitted" : "View Agreement"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr className="titlehr" />

                  <div className="row d-flex mb-2 justify-content-between align-items-center">
                    <div className=" col-3 ms-5 manageBy">
                      <div className="manageByImg">
                        <img src={buglogo} alt="" />
                      </div>
                      <div className="manageByText">
                        <p>Managed by Bugbusterslabs</p>
                      </div>
                    </div>
                    <div className="col-3">
                      {
                        userInfo?.role != 'customer' && <button
                          className="submitReportBtn"
                          onClick={() => {
                            if (userInfo?.role != "admin") {
                              navigate(
                                `/researcher/submissions/new/${program?.id}`
                              );
                            } else {
                              navigate(`/submissions`);
                            }
                          }}
                          disabled={!safeHarbor}
                        >
                          {userInfo?.role == "admin"
                            ? "View Report"
                            : "Submit Report"}
                        </button>
                      }

                    </div>
                    <div className="col-4 text-end">
                      <p
                        style={{
                          color: "#000",
                          fontWeight: "500",
                          marginRight: "40px",
                          marginBottom: "0px",
                        }}
                      >
                        Ends In:{" "}
                        {moment(program?.end_date).format("DD MMM YYYY hh:ss")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row w-100" style={{ backgroundColor: "#fff" }}>
            <div className="col-8">
              <div className="row ">
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "600",
                      marginTop: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    Program Description:
                  </p>
                  <span className="prgmDescSpan">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: program?.project_description,
                      }}
                    ></span>
                  </span>
                </div>
              </div>

              <div className="row">
                <div>
                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        color: "#000",
                        fontWeight: "600",
                        marginTop: "20px",
                        marginBottom: "4px",
                        textDecoration: "underline",
                      }}
                    >
                      Scope Details:
                    </p>
                    <div>
                      <ViewInfo
                        itemValue={scopeDetailsField}
                        option={scopeDetailsOption}
                        containerStyles={{ padding: "0" }}
                        fullWidth={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "600",
                      marginTop: "20px",
                      marginBottom: "4px",
                    }}
                  >
                    Reward Range: Maximum Budget (${program?.maximun_budget})
                  </p>
                </div>
                <div>
                  <table className="table pgrmSummTableP">
                    <thead style={{ backgroundColor: "#F7F7F7" }}>
                      <tr>
                        <th scope="col">Technical severity</th>
                        <th scope="col">Reward</th>
                      </tr>
                    </thead>
                    <tbody>
                      {program?.p1_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{ textAlign: "left", paddingLeft: "25px" }}
                            >
                              <span
                                className="p1Level"
                                style={{ color: "#000" }}
                              >
                                P1
                              </span>
                              <span className="ptext" style={{ color: "#000" }}>
                                {" "}
                                - Critical
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p1_reward_amt}
                          </td>
                        </tr>
                      )}
                      {program?.p2_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{ textAlign: "left", paddingLeft: "25px" }}
                            >
                              <span
                                className="p2Level"
                                style={{ color: "#000" }}
                              >
                                P2
                              </span>
                              <span
                                className="ptext"
                                style={{ color: "#000", color: "#000" }}
                              >
                                {" "}
                                - Severe
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p2_reward_amt}
                          </td>
                        </tr>
                      )}
                      {program?.p3_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{ textAlign: "left", paddingLeft: "25px" }}
                            >
                              <span
                                className="p3Level"
                                style={{ color: "#000", color: "#000" }}
                              >
                                P3
                              </span>
                              <span className="ptext" style={{ color: "#000" }}>
                                {" "}
                                - Moderate
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p3_reward_amt}
                          </td>
                        </tr>
                      )}
                      {program?.p4_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                color: "#000",
                              }}
                            >
                              <span
                                className="p4Level"
                                style={{ color: "#000" }}
                              >
                                P4
                              </span>
                              <span className="ptext" style={{ color: "#000" }}>
                                {" "}
                                - Low
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p4_reward_amt}
                          </td>
                        </tr>
                      )}
                      {program?.p5_reward_amt && (
                        <tr>
                          <td>
                            <div
                              style={{
                                textAlign: "left",
                                paddingLeft: "25px",
                                color: "#000",
                              }}
                            >
                              <span
                                className="p5Level"
                                style={{ color: "#000" }}
                              >
                                P5
                              </span>
                              <span className="ptext" style={{ color: "#000" }}>
                                {" "}
                                - Informational
                              </span>
                            </div>
                          </td>
                          <td
                            className="moneyText"
                            style={{
                              textAlign: "left",
                              paddingLeft: "25px",
                              color: "#000",
                            }}
                          >
                            {program?.p5_reward_amt}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div>
                  {!(program?.p1_reward_amt && program?.p2_reward_amt && program?.p3_reward_amt && program?.p4_reward_amt && program?.p5_reward_amt) && (
                    <div className="p5submission">
                      <i className="fa-solid fa-circle-info"></i>
                      <p>
                        {`${program?.p1_reward_amt ? "" : "P1, "}${program?.p2_reward_amt ? "" : "P2, "}${program?.p3_reward_amt ? "" : "P3, "}${program?.p4_reward_amt ? "" : "P4, "}${program?.p5_reward_amt ? "" : "P5 "}
      Submission do not receive any rewards on this program.`}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    color: "#000",
                    fontWeight: "600",
                    marginTop: "20px",
                    marginBottom: "4px",
                    textDecoration: "underline",
                  }}
                >
                  Resources:
                </p>
              </div>
              <div className="prgmDescAttach">
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "600",

                      marginBottom: "4px",
                    }}
                  >
                    Program Description Attachments:
                  </p>
                </div>
                {[1, 2, 3, 4, 5].map((data) => {
                  if (program && program[`upload_doc${data}`])
                    return (
                      <div key={data} className="d-flex mb-3">
                        <div className="d-flex align-items-center">
                          <i className="fa-solid fa-note-sticky fa-rotate-270"></i>
                        </div>
                        <div>
                          <a
                            target="_blank"
                            href={program[`upload_doc${data}`]}
                            download={true}
                          >
                            <span className="pgrmDescAttachFileName mr-2">
                              {program[`upload_doc${data}`].split("/").at(-1)}
                            </span>
                            <i className="fa-solid fa-download"></i>
                          </a>
                        </div>
                        {/* <div>
                          <a
                            href={program[`upload_doc${data}`]}
                            download={program[`upload_doc${data}`]
                              .split("/")
                              .at(-1)}
                          >
                            <button className="btn btn-download">
                              <i className="fa-solid fa-download"></i>
                            </button>
                          </a>
                        </div> */}
                      </div>
                    );
                })}
              </div>
              <div className="prgmDescAttach" style={{ height: "200px" }}>
                <div>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#000",
                      fontWeight: "600",

                      marginBottom: "4px",
                    }}
                  >
                    Taxonomy Document Attachments:
                  </p>
                </div>
                {[1, 2, 3, 4, 5].map((data) => {
                  if (program && program[`taxonomy_doc${data}`]) {
                    const fileUrl = program[`taxonomy_doc${data}`];
                    const fileName = fileUrl.split("/").at(-1);
                    return (
                      <div key={data} className="d-flex mb-3">
                        <div className="d-flex align-items-center">
                          <i className="fa-solid fa-note-sticky fa-rotate-270"></i>
                        </div>
                        <div>
                          <a
                            target="_blank"
                            href={fileUrl}
                            download={true}
                            rel="noreferrer"
                          >
                            <span className="pgrmDescAttachFileName mr-2">
                              {fileName}
                            </span>
                            <i className="fa-solid fa-download"></i>
                          </a>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              {/* <div className="prgmDescAttach" style={{ height: "500px" }}>
          <div>
            <p
              style={{
                fontSize: "18px",
                color: "#000",
                fontWeight: "600",

                marginBottom: "4px",
              }}
            >
              Other Comments & Upload Documents Here:
            </p>
          </div>
          <div>
            <h4 style={{ color: "#A349A4" }}>
              Discuss with team and have to put info according to the inputs.
            </h4>
          </div>
        </div> */}

              <div className="mt-3 pb-3">
                <div className="row d-flex justify-content-between mt-2">
                  <div className="col-6">
                    {userInfo?.role == "admin" &&
                      <button
                        className="submitReportBtn"
                        onClick={() => {
                          if (userInfo?.role != "admin") {
                            navigate(
                              `/researcher/submissions/new/${program?.id}`
                            );
                          } else {
                            navigate(`/submissions`);
                          }
                        }}
                        disabled={!safeHarbor}
                      >
                        {userInfo?.role == "admin"
                          ? "View Report"
                          : "Submit Report"}
                      </button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      )
      }

      <AgreementModal
        isOpen={agreementModal}
        programId={program?.program_id}
        programTitle={program?.program_title}
        organizationName={
          customer && customer.entity_name && customer.entity_name
        }
        customerName={`${customer.first_name} ${customer.last_name}`}
        customerId={customer.user_id}
        onClose={() => setAgreementModal(false)}
      />
    </>
  );
}
